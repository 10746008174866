var ValueJS = ValueJS || {};

ValueJS.BSBreakpoints = function() {

    var _initialized = false;

    var _html = '';
    _html += '<div id="bsBreakpoint">'
    _html += '<div class="d-block d-sm-none" data-size="xs"></div>';
    _html += '<div class="d-none d-sm-block d-md-none" data-size="sm"></div>';
    _html += '<div class="d-none d-md-block d-lg-none" data-size="md"></div>';
    _html += '<div class="d-none d-lg-block d-xl-none" data-size="lg"></div>';
    _html += '<div class="d-none d-xl-block" data-size="xl"></div>';
    _html += '</div>';

    var _timeoutId = 0;

    var _init = function() {
        if (!_initialized) {
            $('body').append(_html);
            _measure();
            $(window).on('resize', function() {
                if (_timeoutId !== 0) {
                    clearTimeout(_timeoutId);
                }
                _timeoutId = setTimeout(_measure, 1000);
                _measure();
            });
            _initialized = true;
        }
    }

    var _measure = function() {
        var size = $('#bsBreakpoint').find('div:visible').data('size');
        $('body').data('bs-breakpoint', size);
    }

    var _getSize = function() {
        return $('body').data('bs-breakpoint');
    }

    /** PUBLIC API **/
    return {
        init : function() {return _init();},
        getSize: function() {return _getSize();}
    };

}();
