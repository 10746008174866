var Dissertationist = Dissertationist || {};

Dissertationist.Modal = function () {

	var _init = function () {
		$(window).on('load', function () {
			$('#paymentModal').modal('show');
		});
	}

	/** PUBLIC API **/
	return {
		init: function () { return _init(); }
	};

}();

jQuery(function () {
	Dissertationist.Modal.init();
});
