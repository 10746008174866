var Dissertationist = Dissertationist || {};

Dissertationist.Checkout = function () {

	var _init = function () {
	
	}

	/** PUBLIC API **/
	return {
		init: function () { return _init(); }
	};

}();

jQuery(function () {
	Dissertationist.Checkout.init();
});
