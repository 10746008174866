var Dissertationist = Dissertationist || {};

Dissertationist.Logo = function () {
	var logo = $(".brand-logo");
	var togglerbtn = $('.navbar-toggler');


	var _handleTogglerbtn = function() {
		logo.attr("src", "img/layout/web-logo.png").fadeIn(100);
	}

	var _init = function () {
		_changeLogo();
		$(togglerbtn).on('click', _handleTogglerbtn);
	}

	var _changeLogo = function () {

		$(window).scroll(function () {
			var scroll = $(window).scrollTop();
			if (scroll >= 1) {
				logo.attr("src", "img/layout/web-logo.png").fadeIn(100);
			} else {
				logo.attr("src", "img/layout/white-logo.png").fadeIn(100);
			}

		});
	}

	/** PUBLIC API **/
	return {
		init: function () { return _init(); }
	};

}();

jQuery(function () {
	Dissertationist.Logo.init();
});
