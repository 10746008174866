var Dissertationist = Dissertationist || {};

Dissertationist.Expert = function () {
  var _topic = $('.topic');
  var _topicDetail = $('.topic-details .details');
  var _topicImage = $('.topic-image');

  var _handleTopicClick = function(e) {
    e.preventDefault();
    var cacheTopic = $(this);
    var topicName = cacheTopic.data('topic');
    $(_topic).not('data[topic="' + topicName + '"]').removeClass('text-secondary');
    cacheTopic.addClass('text-secondary');
    _activateTopicDetails(topicName);
    _activateTopicImage(topicName);
  }

  var _activateTopicDetails =  function (topicName) {
    $(_topicDetail).each(function() {
      if ($(this).data('topic') ===  topicName) {
        $(this).removeClass('d-none').fadeIn();
      } else {
        $(this).addClass('d-none').fadeOut();
      }
    });
  }

  var _activateTopicImage = function (topicName) {
    $(_topicImage).each(function () {
      if ($(this).data('topic') === topicName) {
        $(this).removeClass('d-none').fadeIn();
      } else {
        $(this).addClass('d-none').fadeOut();
      }
    });
  }


  var _init = function () {
    $(_topic).on('click', _handleTopicClick);
  }

  /** PUBLIC API **/
  return {
    init: function () { return _init(); }
  };

}();

jQuery(function () {
  Dissertationist.Expert.init();
});
