var Dissertationist = Dissertationist || {};

Dissertationist.Swiper = function () {

  var _init = function () {
    var swiper = new Swiper('.swiper-container', {
      spaceBetween: 30,
      loop: true,
      centeredSlides: true,
      autoplay: {
        delay: 250,
        disableOnInteraction: true,
      },
      breakpoints: {
        540: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 4,
        },
        1024: {
          slidesPerView: 5,
        },
      },
    });
  }

  /** PUBLIC API **/
  return {
    init: function () { return _init(); }
  };

}();

jQuery(function () {
  Dissertationist.Swiper.init();
});
