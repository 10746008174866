var VuseJS = VuseJS || {};

VuseJS.Google = VuseJS.Google || {};

VuseJS.Google.TagManager = VuseJS.Google.TagManager || {};

VuseJS.Google.TagManager.Events = function () {

	var _config = {
		clickSelector: '.ga-click-event'
	};

	var _init = function () {
		$('body').on('click', _config.clickSelector, function () {
			dataLayer.push($(this).data('ga-event'));
		});
	}

	/** PUBLIC API **/
	return {
		init: function () { return _init(); },
	};

}();

$(document).ready(function () {
	VuseJS.Google.TagManager.Events.init();
});
