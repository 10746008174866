var Dissertationist = Dissertationist || {};

Dissertationist.Order = function () {

  var words = '';
  var deadlinePrice = '';

  var _makeid = function(length) {
    var result = [];
    var characters = '0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
  }

  var _handleNameOnKeyUp = function () {
    var nameValue = this.value;
    $('.showName').html(nameValue);
  }

  var _handleEmailOnKeyUp = function () {
    var emailValue = this.value;
    $('.showEmail').html(emailValue);
  }

  var _handlePhoneOnKeyUp = function () {
    var phoneValue = this.value;
    $('.showPhone').html(phoneValue);
  }

  var _handlePaperTypeOnChange = function () {
    var val = $(this).find(':selected').val();
    if (val == '') {
      val = '---';
    }
    $('.paperType').html(val);
  }

  var _handleLevelOnChange = function () {
    var val = $(this).find(':selected').val();
    if (val == '*') {
      val = '---';
    }
    $('.studyLevel').html(val);
  }

  var _handleDeadlineOnChange = function () {
    var val = $(this).find(':selected').val();
    var price = $(this).find(':selected').data('rel');
    deadlinePrice = price;
    if (val == '*') {
      val = '---';
    }
    $('.deadline').html(val);
    _calculatePrice();
  }

  var _handleAreaOnChange = function () {
    var val = $(this).find(':selected').val();
    if (val == '*') {
      val = '---';
    }
    $('.subjectArea').html(val);
  }

  var _handleRefrenceStyleOnChange = function () {
    var val = $(this).find(':selected').val();
    if (val == '*') {
      val = '---';
    }
    $('.paperFormat').html(val);
  }

  var _handleWordsOnChange = function () {
    var val = $(this).find(':selected').val();
    var wordsCount = $(this).find(':selected').data('words');
    if (val == '250') {
      words = wordsCount;
    }
    if (val == '*') {
      val = '---';
    }
    words = wordsCount * 10;
    $('.pages').html(val);
    _calculatePrice();
  }

  var _calculatePrice = function() {
    var price = words + deadlinePrice;
    $('.total').html('£ ' + price);
		$('.showPrice').html('£ ' + price);
		$('.hiddenPrice').val(price);
  }


  // var _handleFormSubmit = function (event) {
  //   event.preventDefault(); 
  //   var post_url = $(this).attr("action"); //get form action url
  //   var request_method = $(this).attr("method"); //get form GET/POST method
  //   var form_data = $(this).serialize(); //Encode form elements for submission

  //   $.ajax({
  //     url: post_url,
  //     type: request_method,
  //     data: form_data
  //   });
  // }

  var _init = function () {
    orderId = 'TD-' + _makeid(5);
    $('.order-id').val(orderId);

    $('.paper-type').on('change', _handlePaperTypeOnChange);
    $('#level').on('change', _handleLevelOnChange);
    $('#deadline').on('change', _handleDeadlineOnChange);
    $('#area').on('change', _handleAreaOnChange);
    $('#refrenceStyle').on('change', _handleRefrenceStyleOnChange);
    $('#words').on('change', _handleWordsOnChange);
    $('#name').on('keyup', _handleNameOnKeyUp);
    $('#email').on('keyup', _handleEmailOnKeyUp);
    $('#phoneNumber').on('keyup', _handlePhoneOnKeyUp);
    // $("#OrderForm").on('submit', _handleFormSubmit);
  }

  /** PUBLIC API **/
  return {
    init: function () { return _init(); }
  };

}();

jQuery(function () {
  Dissertationist.Order.init();
});
