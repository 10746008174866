var NahmSpirit = NahmSpirit || {};

NahmSpirit.Navbar = function () {

  var _navbarWrapper = 'div.topnav-wrapper';
  var _navbarWrapperDefault = 'topnav-wrapper--default';
  var _navbarWrapperScrolled = 'topnav-wrapper--scrolled';
  var _targetObservable = 'h1';
  var _navbarLinksWrapper = '#navbarLinks';

  var _isLeaving = false;
  var _offset = 93;

  var _init = function () {
    // check intersection observer browser support
    if (!('IntersectionObserver' in window) ||
      !('IntersectionObserverEntry' in window)) {
      // load polyfill
      jQuery(_navbarWrapper)
        .removeClass(_navbarWrapperDefault)
        .addClass(_navbarWrapperScrolled);
    } else {
      // continue with intersection observer
      var observable = document.querySelector(_targetObservable);
      var observableConfig = {
        root: null,
        rootMargin: '-' + _offset + 'px 0px 0px 0px',
        threshold: 1
      };
      var observer = new IntersectionObserver(_onObserve, observableConfig);
      observer.observe(observable);
    }
    _collapseHandler();
  }

  var _onObserve = function (entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        _isLeaving = true;
        jQuery(_navbarWrapper)
          .removeClass(_navbarWrapperScrolled)
          .addClass(_navbarWrapperDefault);
      } else if (_isLeaving || entry.intersectionRatio === 0) {
        _isLeaving = false;
        jQuery(_navbarWrapper)
          .removeClass(_navbarWrapperDefault)
          .addClass(_navbarWrapperScrolled);
      }
    });
  }

  var _collapseHandler = function () {
    jQuery(_navbarLinksWrapper).on('show.bs.collapse', function () {
      if (jQuery(window).scrollTop() <= _offset) {
        jQuery(_navbarWrapper)
          .removeClass(_navbarWrapperDefault)
          .addClass(_navbarWrapperScrolled);
      }
    })
    jQuery(_navbarLinksWrapper).on('hidden.bs.collapse', function () {
      if (jQuery(window).scrollTop() <= _offset) {
        jQuery(_navbarWrapper)
          .removeClass(_navbarWrapperScrolled)
          .addClass(_navbarWrapperDefault);
      }
    });
  }

  /** PUBLIC API **/
  return {
    init: function () { return _init(); }
  };

}();

jQuery(function () {
  NahmSpirit.Navbar.init();
});

