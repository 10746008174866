var ValueJS = ValueJS || {};

ValueJS.SmoothScroll = function() {

  var _selector = '.smooth-scroll';

  var _defaults = {
    duration : 1000,
    offset : 0
  };

  var _settings = {};

  var _init = function(options) {
    _settings = $.extend({}, _defaults, options || {});
    $('a' + _selector + '[href*="#"]:not([href="#"])').on('click', function(e) {
      if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        var hash = this.hash;
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
          e.preventDefault();
          history.pushState(null, null, hash);
          var navbar = $(this).parents('.navbar-collapse');
          if (navbar.length > 0 && navbar.hasClass('show')) {
            $('.navbar-toggler').trigger('click');
            navbar.on('hidden.bs.collapse', function() {
                navbar.off('hidden.bs.collapse');
                _scroll(target);
            });
          } else {
            _scroll(target);
          }
        }
      }
    });
  }

  var _scroll = function(target, options) {
    var config = $.extend({}, _settings, options || {});
    var scrollPosition = target.offset().top - config.offset;
    if (scrollPosition !== $(document).scrollTop()) {
      $('html, body').animate({
        scrollTop: scrollPosition
      }, config.duration);
    }
  }

  /** PUBLIC API **/
  return {
    init : function(options) {return _init(options);},
    scroll : function(target, options) {return _scroll(target, options);}
  };

}();

$(function() {
  ValueJS.SmoothScroll.init();
});
