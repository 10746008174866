// Images
function importAll(r) {
  return r.keys().map(r);
}
importAll(require.context('../img/layout', true, /\.(png|jpe?g|svg)$/));

// Vendor JS
import './bootstrap/bootstrap';
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';
import './tiny-pubsub/tiny-pubsub.min';
import './valuejs/all-value';
import './vusejs/all-vuse';

// Application JS
import './app/all';
